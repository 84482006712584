import React, { useEffect, useState, useCallback } from 'react';
import {
    APIProvider,
    Map,
    AdvancedMarker,
    Pin,
    InfoWindow,
    AdvancedMarkerAnchorPoint,
    useAdvancedMarkerRef,
    CollisionBehavior
} from '@vis.gl/react-google-maps';
import { Card, Spin, Typography } from 'antd';

interface MapProps {
    enableControls?: boolean;
    main?: boolean;
    loading?: boolean;
    loc?: any[];
}

const AdvancedMarkerWithRef = ({ children, onClick, ...props }: any) => {
    const [markerRef, marker] = useAdvancedMarkerRef();

    return (
        <AdvancedMarker
            onClick={() => {
                if (marker) {
                    onClick(marker);
                }
            }}
            ref={markerRef}
            {...props}
        >
            {children}
        </AdvancedMarker>
    );
};

function MapsGlobal({ enableControls = true, main = true, loading = false, loc = [] }: MapProps) {
    const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number } | null>(null);
    const [mapZoom, setMapZoom] = useState<number>(10);
    const [hoveredMarker, setHoveredMarker] = useState<number | null>(null);
    const [loadingMap, setLoadingMap] = useState(false);
    const [locations, setLocations] = useState<any[]>([]);
    const [selectedMarker, setSelectedMarker] = useState<any>(null);

    const MAP_API_KEY = "AIzaSyDQls-mu7AavtL0jNC1-NDLvAA8OhyL8MQ";
    const MAP_ID = "DEMO_MAP_ID"; // Replace with your Google Maps Map ID

    useEffect(() => {
        setLoadingMap(loading);
    }, [loading]);

    useEffect(() => {
        const lc = loc
            .map((data: any, index: number) => ({
                id: index,
                name: data?.name,
                latitude: data?.location?.latitude,
                longitude: data?.location?.longitude,
                zIndex: loc.length - index // South markers appear in front
            }))
            .filter((location) => location.latitude !== undefined && location.longitude !== undefined);
        setLocations(lc);
    }, [loc]);

    useEffect(() => {
        if (locations.length > 0) {
            const { latMin, latMax, lngMin, lngMax } = locations.reduce(
                (acc, loc) => ({
                    latMin: Math.min(acc.latMin, loc.latitude),
                    latMax: Math.max(acc.latMax, loc.latitude),
                    lngMin: Math.min(acc.lngMin, loc.longitude),
                    lngMax: Math.max(acc.lngMax, loc.longitude),
                }),
                { latMin: Infinity, latMax: -Infinity, lngMin: Infinity, lngMax: -Infinity }
            );

            const center = {
                lat: (latMin + latMax) / 2,
                lng: (lngMin + lngMax) / 2,
            };

            const calculatedZoom = getAdjustedZoom(latMin, latMax, lngMin, lngMax);

            if (!mapCenter || mapCenter.lat !== center.lat || mapCenter.lng !== center.lng) {
                setMapCenter(center);
            }
            if (mapZoom !== calculatedZoom) {
                console.log('calculatedZoom', calculatedZoom);
                setMapZoom(calculatedZoom);
            }
        }
    }, [locations, mapCenter, mapZoom]);

    const getAdjustedZoom = (latMin: number, latMax: number, lngMin: number, lngMax: number): number => {
        const latDiff = latMax - latMin;
        const lngDiff = lngMax - lngMin;
        const maxDiff = Math.max(latDiff, lngDiff);

        const zoom = 7 - Math.log10(maxDiff + 0.0001) * 2.5;
        return Math.min(Math.max(zoom, 3.5), 7);
    };

    const handleMarkerClick = useCallback((index: number, marker: any) => {
        setHoveredMarker(index);
        setSelectedMarker(marker);
    }, []);

    const handleMapClick = useCallback(() => {
        setHoveredMarker(null);
        setSelectedMarker(null);
    }, []);

    if (locations.length === 0) {
        return (
            <Card className="commonFlexDisplay">
                <Typography.Paragraph
                    style={{
                        width: "100%",
                        height: "200px",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "20px",
                    }}
                >
                    No locations available.
                </Typography.Paragraph>
            </Card>
        );
    }

    return (
        <div className="gl-maps-container-global" style={{ height: main ? `calc(100vh - 200px)` : loadingMap ? "275px" : "275px" }}>
            {mapCenter && !loadingMap ? (
                <APIProvider apiKey={MAP_API_KEY}>
                    <Map
                        mapId={MAP_ID}
                        style={{
                            border: "1px solid #d4d4d4",
                            height: "100%",
                        }}
                        draggable={enableControls}
                        zoomControl={enableControls}
                        fullscreenControl={main}
                        fullscreenControlOptions={{ position: window?.google?.maps?.ControlPosition?.LEFT_TOP }}
                        streetViewControl={enableControls}
                        mapTypeControl={enableControls}
                        disableDefaultUI={!enableControls}
                        defaultCenter={mapCenter}
                        defaultZoom={mapZoom}
                        gestureHandling={"greedy"}
                        onClick={handleMapClick}
                    >
                        {locations.map((loc, index) => (
                            <AdvancedMarkerWithRef
                                key={loc.id}
                                position={{
                                    lat: loc.latitude,
                                    lng: loc.longitude,
                                }}
                                onClick={(marker: any) => main && handleMarkerClick(index, marker)}
                                anchorPoint={AdvancedMarkerAnchorPoint.CENTER}
                                title={loc.name || "Unnamed Location"}
                                clickable={true}
                                zIndex={loc.zIndex}
                                collisionBehavior={CollisionBehavior.OPTIONAL_AND_HIDES_LOWER_PRIORITY}
                                style={{
                                    transform: `scale(${hoveredMarker === index ? 1.3 : 1})`,
                                    transformOrigin: AdvancedMarkerAnchorPoint.TOP.join(' ')
                                }}
                            >
                                <Pin
                                    background={hoveredMarker === index ? '#22ccff' : undefined}
                                    borderColor={hoveredMarker === index ? '#1e89a1' : undefined}
                                    glyphColor={hoveredMarker === index ? '#0f677a' : undefined}
                                />
                            </AdvancedMarkerWithRef>
                        ))}

                        {hoveredMarker !== null && main && (
                            <InfoWindow
                                position={{
                                    lat: locations[hoveredMarker].latitude,
                                    lng: locations[hoveredMarker].longitude,
                                }}
                                onCloseClick={() => setHoveredMarker(null)}
                            >
                                <h2>
                                    {locations[hoveredMarker].name || "Unnamed Location"}
                                </h2>
                            </InfoWindow>
                        )}
                    </Map>
                </APIProvider>
            ) : loadingMap ? (
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "20px",
                    }}
                >
                    <Spin spinning={loadingMap} size="large" />
                </div>
            ) : (
                <Typography.Paragraph
                    style={{
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "20px",
                    }}
                >
                    Location unknown
                </Typography.Paragraph>
            )}
        </div>
    );
}

export default MapsGlobal;